import CurrentOffers from "@identitybuilding/idb-react-current-offers";
import DigitalDisplay from "@identitybuilding/idb-react-digital-display";
import Contact from "@identitybuilding/idb-react-contact";
import Team from "@identitybuilding/idb-react-team/dist/Team";
import Header from "./components/Header";
import Translate from "@identitybuilding/idb-react-translations";
import JobApplication from "@identitybuilding/idb-react-job-application/dist/components/vacature/VacatureList";
import OurWork from "@identitybuilding/idb-react-our-works/dist/components/OurWork";
import News from "@identitybuilding/idb-react-news-articles/dist/components/NewsArticles";
import NewsDetail from "@identitybuilding/idb-react-news-articles/dist/components/NewsDetails";
import SocialNews from "@identitybuilding/idb-react-social-news/dist/SocialNews";
import Reviews from "@identitybuilding/idb-react-reviews/dist/Reviews";
import Quote from "@identitybuilding/idb-react-quote/dist/Quote";
import AboutUs from "@identitybuilding/idb-react-about-us";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Vacature from "./components/vacature";
import LoginPage from "./components/LoginPage";
import Floorplan from "./components/Floorplan";
import EShop from "./components/E-Shop";
import Fotoalbum from "./Fotoalbum";
import Instagram from "./components/Instagram"
import Banner from "./components/Banner"
import ToGoodToGo from "./components/ToGoodToGo"
import PanosBanner from "./components/PanosBanner"
import HappyGateau from "./components/HappyGateau"
import TeamImage from "./components/TeamImage"

import defaultImage from "./library/images/download.png";

import OnePage from "./OnePage";
import Admin from "./admin/Admin";
import Policy from "./components/Policy";
import Pdf from "./components/Pdf-page";
import Disclaimer from "./components/Disclaimer";
import { updateModules, updateData, updateIsLoaded } from "./actions";
import "./App.css";
import "./default/Root.css";
import "../node_modules/video-react/dist/video-react.css";

import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateColors } from "./actions";
import axios from "axios";

import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import FetchVideos from "@identitybuilding/idb-react-video-library";
import "@identitybuilding/idb-react-video-library/dist/Style.css"
import Meta from "./Meta";

const App = () => {
  let est = useSelector((state) => state.AdminReducer.est);
  let lang = useSelector((state) => state.AdminReducer.lang);
  let main_lang = useSelector((state) => state.AdminReducer.main_lang);
  let isloaded = useSelector((state) => state.AdminReducer.isloaded);
  let colors = useSelector((state) => state.AdminReducer.colors);
  let data = useSelector((state) => state.AdminReducer.data);

  const [modules, setModules] = useState(useSelector((state) => state.AdminReducer.modules));
  const [creadCollections, setCreadCollections] = useState([]);
  const [digitalDisplay, setDigitalDisplay] = useState([]);
  const [persons, setPersons] = useState([]);
  const [facilities, setFacilities] = useState("");
  const [contactView, setContactView] = useState("");
  const [tab, setTab] = useState(0);
  const [recepten, setRecepten] = useState([]);
  const [allRecepten, setAllRecepten] = useState([]);
  const [suggested_videos, setSuggested_videos] = useState([]);
  const [currentKey, setcurrentKey] = useState(allRecepten[0]);

  // const for contact

  const createNotification = (type, message) => {
    if (type === "info") {
      NotificationManager.info(message);
    } else if (type === "success") {
      NotificationManager.success(message);
    } else if (type === "warning") {
      NotificationManager.warning(message);
    } else if (type === "error") {
      NotificationManager.error(message);
    }
  };

  const navHandler = (tab) => {
    setSuggested_videos(allRecepten[tab]);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    // Update the document title using the browser API
    setRecepten(data.data.options.recepten['aperitif']);
    setSuggested_videos(data.data.options.recepten['aperitif']);
    setcurrentKey('aperitif')
    setAllRecepten(data.data.options.recepten);

  }, [setModules]);

  const translate = (x) => Translate(x, lang);

  let r = document.querySelector(":root");

  const getData = async () => {
    dispatch(updateIsLoaded(false));
    await axios.get(`https://management.100procentlokaal.be/core/api/business/${est}/menu/?lang=${lang}`)
      .then(async (res) => {
        let copy = [...modules];

        dispatch(updateColors(data.colors));
        r.style.setProperty("--main", data.colors.main);
        r.style.setProperty("--sub", data.colors.sub);
        r.style.setProperty("--main-tp", data.colors.main + "ad");
        r.style.setProperty("--sub-tp", data.colors.sub + "ad");
        r.style.setProperty("--main-lg", data.colors.main + "46");
        r.style.setProperty("--sub-lg", data.colors.sub + "46");

        let jsonData = data;
        let entityCopy = jsonData.data;

        // get logos
        let logo_model = require("./models/LogoModel").default;
        await axios
          .get(
            `https://management.100procentlokaal.be/core/api/business/${est}/logos/?lang=${lang}`
          )
          .then((logo_data) => {
            entityCopy.logos = logo_model(logo_data.data, lang);
          })
          .catch((error) => console.log(error));

        for (let i = 0; i < copy.length; i++) {
          // about us
          if (copy[i].slug === "about-us") {
            copy[i].module = (
              <AboutUs
                template={jsonData.data.options.about_us.template}
                theme={jsonData.data.options.about_us.theme}
                title={jsonData.data.options.about_us.title}
                description={jsonData.data.options.about_us.text}
                image={"https://o-sn.be/onepagedemo/about.jpg"}
                colors={colors}
              />
            );
          }

          // team
          if (copy[i].slug === "team") {
            if (copy[i].active) {
              if (entityCopy.options.team.image) {
                copy[i].module = (
                  <TeamImage />
                )
              }
              // copy[i].module = (
              //   <Team
              //     template="3"
              //     theme="light"
              //     team={entityCopy.options.team.team}
              //     title={entityCopy.options.team.title}
              //     description={entityCopy.options.team.description}
              //     socialMedia={entityCopy.options.team.social_media}
              //     hover_animation={entityCopy.options.team.hover_animation}
              //   />
              // );
            }
          }

          // e-shop
          if (copy[i].slug === "e-shop") {
            if (copy[i].active && copy[i].in_app) {
              copy[i].module = (
                <EShop
                  template={entityCopy.options.e_shop.template}
                  theme={entityCopy.options.e_shop.theme}
                  image={entityCopy.options.e_shop.image}
                  title={entityCopy.options.e_shop.title}
                />
              );
            }
          }

          // floorplan
          if (copy[i].slug === "floorplan") {
            if (copy[i].active) {
              copy[i].module = (
                <Floorplan
                  template={entityCopy.options.floorplan.template}
                  theme={entityCopy.options.floorplan.theme}
                  image={entityCopy.options.floorplan.image}
                  title={entityCopy.options.floorplan.title}
                  width="1200px"
                />
              );
            }
          }

          // floorplan
          if (copy[i].slug === "instagram") {
            if (copy[i].active) {
              copy[i].module = (
                <Instagram />
              );
            }
          }
          if (copy[i].slug === "banner") {
            if (copy[i].active) {
              copy[i].module = (
                <Banner />
              );
            }
          }
          if (copy[i].slug === "too-good-to-go") {
            if (copy[i].active) {
              copy[i].module = (
                <ToGoodToGo />
              );
            }
          }
          if (copy[i].slug === "happygateau") {
            if (copy[i].active) {
              copy[i].module = (
                <HappyGateau />
              );
            }
          }
          if (copy[i].slug === "panos-q8") {
            if (copy[i].active) {
              copy[i].module = (
                <PanosBanner />
              );
            }
          }

          //job application
          if (copy[i].slug === "job-application") {
            copy[i].module = (
              <JobApplication
                template={entityCopy.options.job_application.template}
                theme={entityCopy.options.job_application.theme}
                vacature={entityCopy.options.job_application.vacatures}
                title={entityCopy.options.job_application.title}
                mainColor={data.colors.main}
                subColor={data.colors.sub}
              />
            );
          }

          //our work
          if (copy[i].slug === "our-work") {
            copy[i].module = (
              <OurWork
                template={entityCopy.options.our_work.template}
                theme={entityCopy.options.our_work.theme}
                ourWork={entityCopy.options.our_work.work}
                title={entityCopy.options.our_work.title}
              />
            );
          }

          if (copy[i].slug === "social-news") {
            copy[i].module = (
              <SocialNews
                token={entityCopy.socialMedia.token}
                defaultImage={defaultImage}
                facebookId={entityCopy.socialMedia.facebookId}
                title={entityCopy.socialMedia.title}
                instaId={entityCopy.socialMedia.instaId}
                isloaded={isloaded}
              />
            );
          }

          if (copy[i].slug === "reviews") {
            copy[i].module = (
              <Reviews
                template={entityCopy.options.review.template}
                theme={entityCopy.options.review.theme}
                reviews={entityCopy.options.review.review}
                title={entityCopy.options.review.title}
              />
            );
          }

          if (copy[i].slug === "news") {
            copy[i].module = (
              <News
                template={entityCopy.options.news.template}
                theme={entityCopy.options.news.theme}
                news_articles={entityCopy.options.news.articles}
                title={entityCopy.options.news.title}
              />
            );
          }

          if (copy[i].slug === "quote") {
            copy[i].module = (
              <Quote
                template={entityCopy.options.quote.template}
                theme={entityCopy.options.quote.theme}
                quotes={entityCopy.options.quote.quotes}
                title={entityCopy.options.quote.title}
              />
            );
          }

          if (copy[i].slug === "vacature") {
            if (!copy[i].link && copy[i].link == "") {
              copy[i].module = (
                <Vacature
                  template="1"
                  theme="light"
                  title="vacature"
                  company="delhaizeproxymuizen"
                  subject="sollicitatie traiteumedewerker"
                  createNotification={createNotification}
                />
              );
            }
          }

          // contact
          if (copy[i].slug === "contact") {
            entityCopy.menu.openinghours =
              res.data.menu.timetables > 0
                ? true
                : res.data.menu.timetables === -1
                  ? "loading"
                  : false;
            entityCopy.menu.facilities =
              res.data.menu.facilities > 0 ? true : false;
            entityCopy.menu.contacts =
              res.data.menu.persons > 0 ? true : false;

            // Get the timetables
            if (res.data.menu.timetables > 0) {
              let timetableModel = require("./models/TimetableModel")
                .default;
              await axios
                .get(
                  `https://management.100procentlokaal.be/core/api/business/${est}/timetables/?lang=${lang}`
                )
                .then((timetable_data) => {
                  let data = timetableModel(
                    timetable_data.data,
                    lang,
                    translate
                  );
                  entityCopy.openinghours = data;
                })
                .catch((error) => console.log(error));
            }

            // Get denominations
            // Get the names of the ENT / EST
            await axios
              .get(
                `https://management.100procentlokaal.be/core/api/business/${est}/denominations/?lang=${lang}`
              )
              .then((res) => {
                entityCopy.enterprise_name =
                  res.data.denominations.official_name;
                entityCopy.establishment_name = res.data.denominations.name;

                if (
                  res.data.denominations.official_name.toLowerCase() ===
                  res.data.denominations.name.toLowerCase()
                ) {
                  entityCopy.name = res.data.denominations.official_name;
                } else {
                  entityCopy.name = res.data.denominations.name
                    ? res.data.denominations.name
                    : res.data.denominations.official_name;
                }

                // entityCopy.name = res.data.commercial_name ? res.data.commercial_name === res.data.company_name ? res.data.company_name : res.data.commercial_name : res.data.company_name

                entityCopy.juridical_form =
                  res.data.denominations.juridical_form;
                entityCopy.hide_abbreviation_in_front_end = false;

                // entityCopy.vat_number = res.data.enterprise_number;
                entityCopy.establishment_number = est;
              })
              .catch((error) => {
                console.log(error);
              });

            // Get contact persons / Team ?

            // Get address
            await axios
              .get(
                `https://management.100procentlokaal.be/core/api/business/${est}/address/?lang=${lang}`
              )
              .then((res) => {
                let main_address = res.data.address;
                entityCopy.address = {};
                entityCopy.address.lat = main_address.latitude
                  ? String(main_address.latitude)
                  : "";
                entityCopy.address.long = main_address.longitude
                  ? String(main_address.longitude)
                  : "";
                entityCopy.address.postal = main_address.postal_code
                  ? main_address.postal_code
                  : "";
                entityCopy.address.municipality = main_address.municipality
                  ? main_address.municipality
                  : "";
                entityCopy.address.sub_municipality = main_address.sub_municipality
                  ? main_address.sub_municipality
                  : "";
                entityCopy.address.street = main_address.streetname;
                entityCopy.address.number = main_address.house_number;
                entityCopy.address.box = main_address.box_number;
                entityCopy.address.rpr_court = main_address.rpr_court;
                entityCopy.address.rpr_division = main_address.rpr_division;
                entityCopy.address.rpr_lang = main_address.rpr_lang;
                entityCopy.address.extra_info = main_address.extra_address_info;

                if (
                  entityCopy.address.municipality ===
                  entityCopy.address.sub_municipality
                ) {
                  entityCopy.address.sub_municipality = "";
                }

                entityCopy.address.line_1 = `${entityCopy.address.street} ${entityCopy.address.number
                  }${entityCopy.address.box !== "None"
                    ? ` ${entityCopy.address.box}`
                    : ""
                  }`;
                entityCopy.address.line_2 = `${entityCopy.address.postal} ${entityCopy.address.sub_municipality
                  ? entityCopy.address.sub_municipality
                  : entityCopy.address.municipality
                  } ${entityCopy.address.municipality
                    ? entityCopy.address.sub_municipality
                      ? `(${entityCopy.address.municipality})`
                      : ""
                    : ""
                  }`;
              })
              .catch((error) => {
                console.log(error);
              });

            // Get contact data
            let contact_model = require("./models/ContactModel").default;
            await axios
              .get(
                `https://management.100procentlokaal.be/core/api/business/${est}/contacts/?lang=${lang}`
              )
              .then((res) => {
                entityCopy.social_media = contact_model(
                  res.data,
                  lang
                ).social_media;

                entityCopy.telephone = contact_model(
                  res.data,
                  lang
                ).telephone;
                entityCopy.fax = contact_model(res.data, lang).fax;
                entityCopy.cellphone = contact_model(
                  res.data,
                  lang
                ).mobile_phone;
                entityCopy.registration_numbers = contact_model(
                  res.data,
                  lang
                ).registration_numbers;
              })
              .catch((error) => console.log(error));

            if (copy[i].active) {
              copy[i].module = (
                <Contact
                  title={entityCopy.options.contact.title}
                  setTab={(e) => setTab(e)}
                  tab={tab}
                  contactView={contactView}
                  setContactView={(e) => setContactView(e)}
                  parts={entityCopy.menu}
                  entity_id={entityCopy.establishment_number.replace(
                    /\./g,
                    ""
                  )}
                  services={facilities}
                  setServices={(e) => setFacilities(e)}
                  card_theme="main"
                  // id={entityCopy.vat_number
                  //   .replace(/\./g, "")
                  //   .replace("BE", "")}
                  lang={lang}
                  social_media={true}
                  social_media_data={entityCopy.social_media}
                  contacts={persons}
                  setContacts={(e) => setPersons(e)}
                  entity={{
                    zoomed: false,
                    id: entityCopy.vat_number ? entityCopy.vat_number : "",
                    type: "enterprise",
                    logos: entityCopy.logos ? entityCopy.logos : [],
                    enterprise_name: entityCopy.enterprise_name
                      ? entityCopy.enterprise_name
                      : "",
                    vat_number: entityCopy.vat_number
                      ? entityCopy.vat_number
                      : "",
                    establishment_number: entityCopy.establishment_number
                      ? entityCopy.establishment_number
                      : "",
                    juridical_form: entityCopy.juridical_form
                      ? entityCopy.juridical_form
                      : "",
                    address: entityCopy.address ? entityCopy.address : [],
                    telephone: entityCopy.telephone
                      ? entityCopy.telephone
                      : "",
                    cellphone: entityCopy.cellphone
                      ? entityCopy.cellphone
                      : [],
                    fax: entityCopy.fax ? entityCopy.fax : "",
                    facilities: [],
                    social_media: entityCopy.social_media
                      ? entityCopy.social_media
                      : [],
                    contacts: [],
                    registration_numbers: entityCopy.registration_numbers
                      ? entityCopy.registration_numbers
                      : [],
                    opening_hours_reveived: false,
                    openinghours: entityCopy.openinghours,
                    qr: `https://api.ondernemersnetwerk.be/public/business/${"BE" + entityCopy.establishment_number.replace(/\./g, "")}/qr.png`,
                  }}
                />
              );
            }
          }

          // footer
          if (copy[i].slug === "footer") {
            copy[i].module = (
              <Footer
                theme={entityCopy.options.footer.theme}
                contact={entityCopy.options.footer.contact}
                sitemap={entityCopy.options.footer.sitemap}
                name={entityCopy.establishment_name}
                enterprise_name={entityCopy.enterprise_name}
                address={entityCopy.address.line_1}
                city={entityCopy.address.line_2}
                phone={entityCopy.telephone}
                cellphone={entityCopy.cellphone.length > 0 ? entityCopy.cellphone[0] : ""}
                vat={entityCopy.vat_number}
                socialMedia={entityCopy.social_media}
              />
            );
          }

          // current-offers
          if (copy[i].slug === "ons-verhaal") {
            if (res.data.menu.creads.length > 0) {
              if (copy[i].active) {
                copy[i].module = (
                  <CurrentOffers
                    id={est}
                    lang={lang}
                    creadCollections={creadCollections}
                    setCreadCollections={(e) => setCreadCollections(e)}
                    title={'our_story'}
                    bpIndex={'all'}
                  />
                );
              }
            } else {
              copy[i].active = false;
            }
          }

          // Digital display
          if (copy[i].slug === "digital-display") {
            if (res.data.menu.cmps.length > 0) {
              if (copy[i].active) {
                copy[i].module = (
                  <DigitalDisplay
                    params={"?sort=d"}
                    id={est}
                    cmpsCollections={digitalDisplay}
                    setCmpsCollections={(e) => setDigitalDisplay(e)}
                    lang={lang}
                    bpIndex={'all'}
                    url_catalogue={`https://www.catalogusnetwerk.be/business/${res.data.est.search_id}`}
                    url_promonetwork={`https://www.promonetwerk.be/business/${res.data.est.search_id}`}
                  />
                );
              }
            } else {
              copy[i].active = false;
            }
          }
          // Header module
          if (copy[i].slug === "home") {
            if (copy[i].active) {
              copy[i].module = (
                <Header
                  type={entityCopy.options.home.type}
                  name={"IdentityBuilding"}
                  logo={entityCopy.logos.length > 0 ? entityCopy.logos[0].logo : ""}
                  slogan={entityCopy.options.home.slogan}
                  image={entityCopy.options.home.image}
                  video={entityCopy.options.home.video}
                  fullscreen={entityCopy.options.home.fullscreen}
                  logo_and_slogan_visibility={entityCopy.options.home.logo_and_slogan_visibility}
                  logo_visibility={entityCopy.options.home.logo_visibility}
                  height={entityCopy.options.home.height}
                  theme={entityCopy.options.home.theme}
                  overlay_opacity={entityCopy.options.home.overlay_opacity}
                  slogan_template={entityCopy.options.home.slogan_template}
                />
              );
            }
          }
        }
        // endfor

        dispatch(updateModules(copy));
        dispatch(updateData(entityCopy));
        dispatch(updateIsLoaded(true));
        setModules(copy);
      });

  };

  useEffect(() => {
    getData();
    if (!window.location.href.includes("lang=")) { window.location.href = `${window.location.href}?lang=${main_lang}` }
  }, [est]);


  return (
    <BrowserRouter basename="">
      {isloaded ?
        <div className="App">
          <Meta data={data} />
          <Switch>
            {/* <Route
              exact
              path="/admin"
              render={(props) => <Admin {...props} />}
            /> */}
            <Route exact path="/vacature/:id" render={(props) => <Pdf {...props} />} />
            <Route exact path="/:id?/pages/policy" render={(props) => <Policy {...props} />} />
            <Route exact path="/:id?/pages/disclaimer" render={(props) => <Disclaimer {...props} />} />

            <Route path="/news-details/:id">
              <Nav hover_animation="underline" />
              <NewsDetail />
            </Route>
            {/* <Route path="/login">
              <Nav hover_animation="underline"/>
              <LoginPage />
            </Route> */}
            <Route path="/fotoalbum">
              <Nav hover_animation="underline" />
              <Fotoalbum />
            </Route>
            <Route path="/recepten">
              <Nav hover_animation="underline" />
              <FetchVideos
                theme="light"
                videolist={recepten}
                allRecepten={allRecepten}
                suggested_videos={suggested_videos}
                onNav={(e) => navHandler(e)}
                currentKey={currentKey}
                lang={lang}
              />
              <Footer
                theme={data.options.footer.theme}
                contact={data.options.footer.contact}
                sitemap={data.options.footer.sitemap}
                name={data.establishment_name}
                enterprise_name={data.enterprise_name}
                address={data.address.line_1}
                city={data.address.line_2}
                phone={data.telephone}
                cellphone={
                  data.cellphone.length > 0
                    ? data.cellphone[0]
                    : ""
                }
                vat={data.vat_number}
                socialMedia={data.social_media}
              />
            </Route>
            <Route path="/:id?/" render={(props) => <OnePage {...props} />} />
          </Switch>
        </div>
        :
        <div id="loadingPage">
          <div className="loader">{lang === 'nl' ? 'Even geduld a.u.b.' : 'Un instant s.v.p'}</div>
        </div>
      }
      <NotificationContainer />
    </BrowserRouter>
  );
};
export default App;
