import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const TeamImage = () => {

    const { lang, translate, folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="teamImage">
            <h2>{translate('our_team')}</h2>
            <figure>
                <img src={`https://o-sn.be/kml/test-onepages/${folder_name}/team.jpg`}/>
            </figure>
        </section>
    )
}

export default TeamImage