import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { Button } from '@identitybuilding/idb-react-ui-elements';
import Icon from '@identitybuilding/idb-react-iconlib';
const Instagram = () => {
    const { instagram_name, instagram_id } = useSelector((state) => state.AdminReducer);


    return (
        <section id="instagram">
            <h2 style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}><Icon name="Instagram" /> #{instagram_name} </h2>
            <div style={{ "margin": "43px 0" }} className={instagram_id}></div>
            <div style={{ "display": "flex", "justifyContent": "center" }}>
                <Button
                    bgColor={['main']}
                    icon='Instagram'
                    size='M'
                    text={instagram_name}
                    txtColor='white'
                    type='main'
                    url={`https://www.instagram.com/${instagram_name}/`}
                />
            </div>
        </section>
    )
}

export default Instagram